.userMenu{
    width:376px;
    height:540px;
    padding:30px 30px;
     margin-top:-19px;
     margin-left:-32px;
     /* border: 3px solid red; */
}

@media screen and (max-width: 1000px) {
    .userMenu{
        /* margin-left: 100%; */
        margin: auto;
    }
  }