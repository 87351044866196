.categoryCardContainer{
    width: 100%;
    display: grid;
    padding: 10px 30px;
    grid-template-columns: repeat(6,1fr);
    gap: 10px;
    margin-bottom: 150px;
    /* border: 1px solid red; */
    /* margin-top: 10px; */
    /* background-color: #f2eae8; */
}

.categoryLeftbar > img{
   width: 100%;
   height: 500px;
   filter: blur(6px);
  -webkit-filter: blur(6px);
}



.CategorySidebar{
    border: 2px solid grey;
}

.categoryLeftbar > h3{
    font-size: 25px;
    /* margin-top: 50px; */
    margin-left: 30px;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 600;
}

/* .catBodyContainer{
    display: grid;
    grid-template-columns: 20% 80%;
    border: 3px solid red;
    background-color: #f2eae8;
} */

.categoryCard{
    border: 1px solid rgb(218, 211, 211);
    text-align: center;
    border-radius: 5px;
    height: 190px;
    background-color: rgb(255, 255, 255);
}

.categoryCard > img{
  height: 130px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.categoryCard > h2{
    color: black;
    font-size: 20px;
    font-weight: 500;
}


@media screen and (max-width: 1180px) {
    .categoryCardContainer{
        display: grid;
        grid-template-columns: repeat(4,1fr);
    }
   }

   @media screen and (max-width: 990px) {
    .categoryCardContainer{
        display: grid;
        grid-template-columns: repeat(3,1fr);
    }
   }

   @media screen and (max-width: 700px) {
    
    .categoryCardContainer{
        width: 90%;
        display: grid;
        grid-template-columns: repeat(2,1fr);
        gap: 20px;
    }
    
   }