.product-images {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 400px;
  /* border: 5px solid black; */
  position: relative;
}

.main-image {
  position: relative; /* Changed from absolute to relative */
  width: calc(100% - 150px);
  height: 425px;
  margin-top: 27px;
  /* border: 1px solid black; */
  /* border: 5px solid red; */
}

.main-image img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container */
}

.thumbnails {
  position: relative;
  display: flex;
  flex-direction: column;
  /* gap: 1px; */
  align-items: center;
  justify-content: space-between;
  width: 150px;
  height: 100%;
}

.thumbnail {
  width: 100%;
  height: 35.6%;
  cursor: pointer;
  opacity: 0.5;
  /* text-decoration: none; */
  transition: opacity 0.3s ease-in-out;
}

.thumbnail.active,
.thumbnail:hover {
  opacity: 1;
}

.thumbnail img {
  width: 100%;
  height: 100%;
  /* border: 1px solid grey; */
  border: 1.5px solid rgb(203, 198, 198);
  object-fit: cover;
}

@media screen and (max-width: 1270px) {
  .product-images{
      display: grid;
      grid-template-columns: 40% 50%;
      height: 530px;
  }

  .main-image {
    position: relative; /* Changed from absolute to relative */
    width: calc(100% - 160px);
    height: 425px;
    margin-top: 27px;
    /* border: 1px solid black; */
    /* border: 5px solid red; */
  }
 }
