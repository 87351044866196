@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&family=Poppins:wght@300;400&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* overflow: visible; */
  
}

/* body{
  background: #c7b4a2;

} */

/* font-family: 'Playfair Display', serif; */
/* font-family: 'Poppins', sans-serif; */

 /* ==========================  navbar css  ============================= */



 /* ==========================   footer css  ========================= */

 /* .footer{
    color: white;
    padding: 45px 25px;
    width: 100%;
    background-color: black;
 }

 .inpt{
  display: flex;
  padding: 0px 30px;
  justify-content: space-between;
  width: 90%;
 }

 .inpt > input{
  width: 70%;
  padding: 10px 10px;
  border: transparent;
  border-radius: 5px;
  margin-left: 40px;
 }

 .inpt > button{
  width: 100px;
  margin-left: 20px;
  border: 1px solid white;
  color: white;
  background-color: transparent;
  border-radius: 5px;
 }

 .inpt > button:hover{
  background-color: rgb(147, 147, 20);
  color: white;
 }

 .endFt{
  display: flex;
  width: 70%;
  justify-content: space-between;
  margin: auto;
  margin-top: 30px;
 }

 

 .endFt > div:nth-child(2){
  width: 40%;
 }

 .endFt > div > p:hover{
   color: rgb(122, 122, 20);
   cursor: pointer;
 }

 .footer a{
  text-decoration: none;
  color: white;
  padding: 10px;
 }

 .footer a:hover{
  color: #ffefba;
  border-bottom: 1px solid #ffefba;
 } */


  /* =============================== page not found page css  ============================== */

  .pnf{
    display: flex;
    min-height: 65vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .pnf-title{
    font-size: 100px;
    font-weight: 700;
  }

  .pnf-heading{
    font-weight: normal;
  }

  .pnf-btn{
    color: black;
    border:  1px solid black;
    text-decoration: none;
    padding: 10px;
    margin-top: 10px;
  }


  /*  =======================  contact page css  ============================== */

  .contactus {
    margin: 0;
    padding: 0;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

/* product link  */
.product-link{
  text-decoration: none !important;
  color: black;
}