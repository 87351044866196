/* .product-details-container {
    padding: 40px 50px;
  } */
  
  .product-title {
    font-size: 2.2rem;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 600;
    color: #ec9e67;
    /* margin-top: -210px; */
  }

  .prod-price{
    font-family: 'Courier New', Courier, monospace;
    font-weight: 600;
    
  }

  .rating{
    margin-top: 10px;
    color: grey;
    margin-bottom: 20px;
  }
  
  .product-rating {
    color: #ffc107;
    text-align: left;
  }

  .prod-dis{
    margin-top: 20px;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 500;
  }
  

  .prContainer{
    display: grid;
    grid-template-columns: 50% 50%;
    height: 430px;
    justify-content: space-between;
    width: 90%;
    margin: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-top: 40px;
    /* border: 2px solid red; */
  }
  
  .product-details-info {
    width: 100%;
    display: flex;
    padding: 30px 60px;
    flex-direction: column;
    justify-content: center;
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
  }
  


  .productContainer2{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 30px;
    padding: 30px 50px;
    margin-top: 40px;
    /* border: 1px solid red; */
    
}

@media screen and (max-width: 1270px) {
  .prContainer{
      display: grid;
      grid-template-columns: 40% 50%;
      height: 530px;
  }
 }


@media screen and (max-width: 1180px) {
    .container2{
        display: grid;
        grid-template-columns: repeat(4,1fr);
    }
   }

   @media screen and (max-width: 990px) {
    .container2{
        display: grid;
        grid-template-columns: repeat(3,1fr);
    }

    .prContainer{
      display: grid;
      grid-template-columns: 80%;
      height: 1000px;
    }
   }

   @media screen and (max-width: 700px) {
    
    .container2{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        gap: 8px;
    }
    
   }
  
  .btn-secondary {
    background-color: #333;
    border-color: #333;
    width: 120px;
    padding: 5px 5px;
    text-align: center;
  }
  
  .btn-secondary:hover {
    background-color: #444;
    border-color: #444;
  }
  
  .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .btn-primary:hover {
    background-color: #0069d9;
    border-color: #0062cc;
  }