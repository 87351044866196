
.bigContainer{
    width: 100%;
    margin: auto;
    /* background: #c7b4a2; */
    /* border: 1px solid red; */
}

.container1{
    padding: 10px 20px;
    /* margin-top: 50px; */
    /* border: 1px solid red; */
    margin-top: -90px;
}

.container1 > h2{
    font-family: 'Courier New', Courier, monospace;
    font-weight: 600;
    text-align: center;
}

.img-title{
    position: relative;
    z-index: 1;
    color: white;
    bottom: 18%;
    left: 40px;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 540;
}

.img-dis{
    position: relative;
    z-index: 1;
    color: white;
    bottom: 18%;
    left: 40px;
    width:40%;
}

.container2{
    /* width: 100%; */
    /* border: 2px solid blue; */
    display: grid;
    grid-template-columns: repeat(5,1fr);
    gap: 20px;
}

.card:hover{
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}


.abt{
    width: 75%;
    height: 250px;
    margin: auto;
    /* border: 1px solid red; */
    display: grid;
    grid-template-columns: 40% 60%;
    margin-top: 90px;
    padding: 10px 10px;
    background-color: white;
    border-radius: 10px;
    margin-bottom: 60px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.abt > img{
    width: 70%;
    height: 200px;
    margin-left: 50px;
    margin-top: 25px;
}

.abt-dis{
    display: grid;
    justify-content: center;
    /* text-align: center; */
    align-items: center;

}

.abt-dis > h2{
    margin-top: 10px;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 600;
}

.abt-dis > p{
    margin-top: -25px;
    /* font-family: 'Courier New', Courier, monospace; */
    font-weight: 600;
    color: grey;
}

.abt-btn{
    width: 100px;
    padding: 5px 5px;
    border: transparent;
    background-color: rgb(240, 176, 37);
    color: white;
    border-radius: 5px;
    margin-bottom: 20px;
}

/* .vision{
    width: 95%;
    margin: auto;
}

.vis{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    width: 80%;
    margin: auto;
    height: 200px;
}

.vis>img{
    width: 100%;
    height: 200px;
}

.visCard{
    background-color: rgb(172, 120, 89);
    padding: 5px 30px;
}

.visCard>p{
    color: rgb(248, 245, 245);
    font-size: 16px;
    font-weight: 600;
    margin-top: 25px;
} */

@media screen and (max-width: 950px) {
   .sidebar{
       display: none;
   }
   .container1{
    width: 100%;
   }

   .topBanner{
    height: 150px;
   }
  }

  @media screen and (max-width: 1180px) {
    .container2{
        display: grid;
        grid-template-columns: repeat(4,1fr);
    }
   }

   @media screen and (max-width: 990px) {
    .container2{
        display: grid;
        grid-template-columns: repeat(3,1fr);
    }
   }

   @media screen and (max-width: 780px) {
    
    .container2{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        gap: 8px;
    }
    
   }

   @media screen and (max-width: 550px) {
    
    .container2{
        display: grid;
        grid-template-columns: 80%;
        gap: 8px;
    }
    
   }

  /* @media screen and (max-width: 1541px) {
    .container1{
        align-items: center;
        justify-content: center;
    }
   } */



.bannerSlider{
    margin-top: 40px;
}