.cartContainer{
    /* border: 2px solid red; */
    /* display: block; */
    text-align: center;
    background-color: rgb(197, 186, 186);
    /* width: 101%; */
}

.cartTitle{
    /* border: 2px solid black; */
    font-family: 'Courier New', Courier, monospace;
    padding: 5px 5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

.cartTitle > h1{
    /* font-family: Harrington; */
    font-size: 25px;
    margin-top: 5px;
    font-weight: 600;
    color: #080005;
}


.emptyImage{
    margin-top: 20px;
    padding: 5px 5px;
    margin-bottom: 30px;
}

.cartButton {
    border: none;
    padding: 0.6rem;
    outline: none;
    color: white;
    text-transform: uppercase;
    font-weight: 600;
    font-style: 1.2em;
    letter-spacing: 0.1em;
    position: relative;
    text-decoration: none;
    z-index: 1;
    cursor: pointer;
    background: none;
    margin-top: 35px;
    margin-bottom: 30px;
    text-shadow: 3px 3px 10px rgba(0,0,0,.45);
    &:before, &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      border-radius: 10em;
      transform: translateX(-50%) translateY(-50%);
      width: 105%;
      height: 105%;
      content: '';
      z-index: -2;
      background-size: 400% 400%;
      background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
    }
    &:before {
      filter: blur(7px);
      transition: all .25s ease;
      animation: pulse 10s infinite ease;
    }
    &:after {
      filter: blur(0.3px);
    }
    &:hover {
      &:before {
        width: 115%;
        height: 115%;
      }
    }
  }


  .cartPrdct{
    display: flex;
    gap: 30px;
    padding: 30px 50px;
    /* border: 1px solid blue; */
  }

  .singleCart{
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 5px;
    padding: 20px 20px;
    background-color: #d08a5e;
    margin-bottom: 30px;
    color: white;
    /* width: 80%; */
  }

  .cartSingleImage > img{
    width: 100%;
    height: 150px;
    border-radius: 10px;
  }

  .paymentCheck{
    /* border: 3px solid yellow; */
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 100%;
    padding: 20px 20px;
    /* height: 35rem; */
    background-color: #c9b1a1;
    color: white;
    border-radius: 5px;
  }