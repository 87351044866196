.categoryContainer{
    /* border: 1px solid blue; */
    margin-bottom: 30px;
}

.catBodyCont{
    display: grid;
    grid-template-columns: 10% 90%;
    border: 3px solid red;  
}

.categoryContainer2{
    width: 100%;
    margin-left: 55px;
    /* border: 1px solid red; */
    display: grid;
    grid-template-columns: repeat(4,1fr);
    justify-content: space-between;
    gap: 20px;
    margin-top: 30px;
}