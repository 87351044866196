
.searchContainer2{
    /* border: 2px solid red; */
    padding: 40px 40px;
}

.searchContainer2 > h2{
  font-family: 'Courier New', Courier, monospace;
}

.searchContainer2{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5,1fr);
    gap: 30px;
    padding: 30px 50px;
    margin-top: 40px;
    /* border: 1px solid red; */
    
}


@media screen and (max-width: 1180px) {
    .container2{
        display: grid;
        grid-template-columns: repeat(4,1fr);
    }
   }

   @media screen and (max-width: 990px) {
    .container2{
        display: grid;
        grid-template-columns: repeat(3,1fr);
    }
   }

   @media screen and (max-width: 700px) {
    
    .container2{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        gap: 8px;
    }
    
   }